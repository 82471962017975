@font-face {
  font-family: "Kanit";
  src: url("./assets/fonts/Kanit-Regular.eot");
  src: url("./assets/fonts/Kanit-Regular.eot?#iefix") format("embedded-opentype"),
    url("./assets/fonts/Kanit-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

body {
  max-width: 425px;
  margin: 0 auto !important;
  font-family: "Kanit";
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.swal2-title {
  font-family: "Kanit" !important;
}

.swal2-html-container {
  font-family: "Kanit" !important;
}
.swal2-actions button {
  font-family: "Kanit" !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
